.lightning {
  margin: 0;

  .mapContainer {
    position: absolute;
    top: 80px;
    bottom: 30px;
    left: 0;
    right: 0;
    float: left;
    display: block;
    background-color: rgba(100, 100, 100, 0.4);

    .fullHeight {
      height: 100%;
    }

    .map {
      height: 100%;
    }
  }

  .loaderDiv {
    position: absolute;
    left: 50%;
    top: 40%;

  }

  .insideLoaderDiv {
    position: relative;
    left: -50%;
    background-color: #E0E5EE;
    border: solid 1px black;
    font-family: Sans-Serif;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
    width: 240px;
  }

  .controlBox {
    position: absolute;
    top: 90px;
    right: 70px;
    width: 348px;
    height: 130px;
    background-color: rgba(255, 255, 255, 0.75);
    padding-left: 4px;
    padding-right: 4px;
    color: #444444;
    border-radius: 3px;

    .row {
      position: relative;
      float: left;
      width: 100%;
      font-size: 12px;

      .strikeTypeField {
        position: relative;
        float: left;
        width: 77px;
        padding: 5px 0;
        margin-right: 8px;
        font-size: 15px;
        font-weight: bold;

        img {
          float: right;
          margin-top: .3rem;
        }
      }

      .olderStrikeField {
        position: relative;
        float: left;
        width: 25px;
        padding: 5px;
        font-size: 10px;
        margin-top: -5px;
        text-align: center;
      }

      .olderStrikeLabel {
        position: relative;
        float: left;
        width: 25px;
        padding: 5px;
        font-size: 10px;
        margin-top: -10px;
        text-align: center;
      }
    }

    .strikeCountRow {
      position: absolute;
      height: 17px;
      width: 100%;
      top: 34px;
      margin-top: 6px;

      .strikeCountField {
        position: relative;
        float: left;
        width: 77px;
        text-align: right;
        margin-top: -9px;
        font-size: 12px;
        color: #444444;
        font-weight: bold;
        padding: 0;
        margin-right: 8px;
      }
    }

    .sliderContainer {
      position: relative;
      float: right;
      width: 210px;
      margin: 3px 10px 0 0;
    }

    .totalStikeField {
      position: relative;
      float: left;
      font-size: 12px;
    }

    .lastStikeField {
      position: relative;
      float: right;
      font-size: 12px;
    }

    .olderStrikeContainer
    {
        position: relative;
        float: left;
        width: 100px;
    }
  }

  .strikeRectContainer {
    width: 345px;
    height: 17px;
  }

  rect{
    &.outline {
      stroke: #444444;
      stroke-width: 2px;
      fill-opacity: 0.1;
    }

    &.cgPos {
      fill: #A349A4;
    }
  
    &.cgNeg {
      fill: #FF0000;
    }
  
    &.icPos {
      fill: #F086FA;
    }
  
    &.icNeg {
      fill: #2BFF32
    }
  }

  .hide {
    display: none;
  }
}
