body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.overlay
    {
        position: absolute;
        top:0px;
        left:0px;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }

    .overlay .spinnerContainer
    {
        position: absolute;
        top: 40%;
        height: 70px;
        width: 200px;
        left: 50%;
        margin-left: -75px;
        background: #ffffff;
        border: 2px solid #555555;
        color: var(--main-background);
        padding: 10px;
        border-radius: 3px;
    }

    .overlay .spinner
    {
        font-size: 50px;
    }

    .overlay .loadingText
    {
        font-size: 26px;
        margin-top: 7px;
        display: block;
        float: right;
    }
