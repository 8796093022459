.dark-theme {
  --main-background: #222222;
  --box-background: #2A2A2B;
  --map-bubble-background: #393939;
  --summary-label-background: #3D424D;

  --weather-value-border: #3D424D;

  --odd-grid-row: #393939;

  --text-color: #f1f1f1;
  --text-link-color: #8DB9FC;
  --red-text: #FF9999;
  --blue-text: #66BDFF;

  --temperature-color: #C3602D;
  --thsw-index-color: #FF9C7A;
  --heat-index-color: #FF8AFF;
  --wind-chill-color: #5CDCFF;
  --humidity-color: #33CCFF;
  --dew-point-color: #00CCCC;
  --uv-index-color: #D8B164;
  --barometer-color: #C2ADFF;
  --solar-radiation-color: #E5A69A;
  --high-wind-speed-color: #FF8F8F;
  --average-wind-speed-color: #98BADD;
  --rainfall-color: #89BCEC;
}

body
{
    background-color: var(--main-background);
    color: var(--text-color);
    font-family: "Segoe UI",Verdana,Helvetica,Sans-Serif;
    margin: 0;
    padding: 0;
}

.App {
  font-size: .85em;
}

    .App .overallContainer
    {
        width: 100%;
        min-width: 850px;
    }


.clear
{
  clear: both;
}

.rc-calendar {
    background-color: var(--box-background);
    color: var(--text-color);
}

.footer {
    position: relative;
    width: 100%;
    padding-right: 20px;
    text-align: right;
}

.no-right-border {
    border-right: none 0 !important;
}

.p-dropdown, .p-dropdown-panel {
    background-color: var(--main-background) !important;
    color: var(--text-color) !important;
}
.p-inputtext, .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: var(--text-color) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):hover {
    color: var(--main-background) !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: var(--summary-label-background) !important;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: 0 0 0.2rem 0.2rem #888888 !important;
    border-color: #888888 !important;
}

.redText {
  color: var(--red-text);
}

.blueText {
  color: var(--blue-text);
}

.temperature {
  color: var(--temperature-color);
}

.thswIndex {
  color: var(--thsw-index-color);
}

.heatIndex {
  color: var(--heat-index-color);
}

.windChill {
  color: var(--wind-chill-color);
}

.humidity {
  color: var(--humidity-color);
}

.dewpoint {
  color: var(--dew-point-color);
}

.uvIndex {
  color: var(--uv-index-color);
}

.barometer {
  color: var(--barometer-color);
}

.solarRadiation {
  color: var(--solar-radiation-color);
}

.highWindSpeed {
  color: var(--high-wind-speed-color);
}

.averageWind {
  color: var(--average-wind-speed-color);
}

.rainfall {
  color: var(--rainfall-color);
}


