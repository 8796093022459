.weatherCalendar {
  position: relative;
  top: 10px;
  left: 50%;
  margin-left: -610px;
  min-width: 1220px;
  font-size: .85em;

  .p-calendar {
    width: 100%;
    height: 100%;

    .p-datepicker {
      background-color: transparent;
      color: var(--text-color);
      border: 0 none;

      table {
        tr {
          td {
            span {
              &:hover {
                background-color: #57B6FF;
                color: #242424;
              }
            }
          }
        }
      }

      .p-datepicker-header {
        background-color: transparent;
        color: var(--text-color);

        .p-datepicker-year, .p-datepicker-month {
          color: var(--text-color);
        }
      }

      .p-monthpicker {
        .p-monthpicker-month {
          &:hover {
            background-color: #57B6FF;
            color: #242424;
          }
        }
      }
    }
  }
}

.weatherCalendar .calendarContainer {
  clear: both;
}

.weatherCalendar .leftSideContainer {
  position: absolute;
  top: 0;
  height: 885px;
  left: -60px;
  width: 420px;
  background-color: var(--box-background);
  padding: 5px;
  border: solid 1px #000000;
  border-radius: 5px;
  box-shadow: 0 0 5px 3px #888888;
  box-sizing: unset;
}

.weatherCalendar .calendarContainer {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: unset;
}

.weatherCalendar .rc-calendar-full {
  width: 100%;
}

.weatherCalendar .weatherDataContainer {
  position: relative;
  left: 390px;
  top: 0;
  width: 830px;
  height: 875px;
  background-color: var(--box-background);
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 3px #888888;
  box-sizing: unset;
  margin-bottom: 50px;
}

.weatherCalendar .weatherDataContainer h2 {
  margin: 0;
}

.weatherCalendar .summaryContainer {
  position: relative;
  float: left;
  margin-top: 5px;
}

.weatherCalendar .summaryContainerCol2 {
  position: relative;
  float: left;
  margin-top: 5px;
  margin-left: 8px;
}

.weatherCalendar .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container,
.weatherCalendar .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
  min-height: 0 !important;
}

.weatherCalendar .row2 {
  margin-top: 16px;
}

.weatherCalendarTable.p-datatable .p-datatable-tbody>tr>td {
  padding: 0 .2rem;
  font-size: .85rem;
}

.weatherCalendarTable.p-datatable .p-datatable-tbody>tr:nth-child(odd) {
  background-color: var(--odd-grid-row);
  color: var(--text-color);
}

.weatherCalendarTable.p-datatable .p-datatable-tbody>tr:nth-child(even) {
  background-color: var(--box-background);
  color: var(--text-color);
}

.weatherCalendarTable.p-datatable .p-datatable-thead>tr>th {
  background-color: var(--summary-label-background);
  color: var(--text-color);
}

.weatherCalendarTable.p-datatable .p-row-toggler-icon {
  visibility: hidden;
}


.weatherCalendarTable .pi {
  font-size: .9rem;
  margin-left: -.5rem;
}

.weatherCalendar .summaryContainerSelectedAverages {
  position: relative;
  float: left;
  margin-top: 25px;
  margin-left: 8px;
}

.weatherCalendar .griddle-table-heading {
  color: #FFFFFF;
  font-weight: bold;
  height: 29px;
}

.weatherCalendar .griddle-table-heading-cell {
  background-color: var(--summary-label-background) !important;
}

.weatherCalendar .weatherTypeHeader {
  width: 205px;
}

.weatherCalendar .valueHeader {
  width: 95px;
}

.weatherCalendar .miscHeader {
  width: 100px;
}

.weatherCalendar .griddle-row {
  height: 30px;
}

.weatherCalendar .griddle-cell {
  width: 100%;
  padding: 0 5px;
}

.weatherCalendar .alignRight {
  text-align: right;
}

.weatherCalendar .griddle-table {
  border: solid #D4D4D4 1px;
  border-collapse: collapse;
}

.weatherCalendar .griddle-table th,
td {
  border: solid #D4D4D4 1px;
}

.weatherCalendar .griddle-table tr:nth-child(odd) {
  background-color: #555555;
}

.weatherCalendar .griddle-noResults {
  display: none;
}

.weatherCalendar .hide {
  display: none;
}

.weatherCalendar .rc-calendar {
  background-color: var(--box-background);
  color: var(--text-color);
}

.weatherCalendar .rc-calendar-date {
  color: #c1c1c1;
}

.weatherCalendar .rc-calendar-next-month-btn-day .rc-calendar-date {
  color: #919191;
}

.weatherCalendar .rc-calendar-full-header-switcher-focus {
  background-color: var(--summary-label-background);
  color: var(--text-color);
}

.weatherCalendar .rc-calendar-full-header-switcher>span {
  color: var(--text-color);
}

.weatherCalendar .rc-select-selection {
  background-color: #555555;
  color: var(--text-color);
}

.weatherCalendar .rc-calendar-selected-day .rc-calendar-date {
  background-color: #777777;
  color: var(--text-color);
}

.weatherCalendar .ag-row-first {
  border-top: none 0 !important;
}

.weatherCalendar .ag-row-last {
  border-bottom: none 0 !important;
}

.weatherCalendar .ag-header-cell {
  background-color: var(--summary-label-background);
  color: var(--text-color);
  border-right: solid 1px var(--text-color);
}

.weatherCalendar .ag-header-cell-label .ag-header-cell-text {
  width: 100%;
  text-align: center;
}

.weatherCalendar .ag-theme-alpine .ag-ltr .ag-cell {
  line-height: unset;
  padding: 5px;
  border-right: solid 1px var(--text-color);
}

.weatherCalendar .ag-row {
  color: var(--text-color);
}

.weatherCalendar .ag-row-odd {
  background-color: var(--box-background);
}

.weatherCalendar .ag-row-even {
  background-color: #555555;
}