.yearlySummary {
  position: relative;
  top: 10px;
  left: 50%;
  margin-left: -235px;
  width: 470px;
  font-size: .85em;

  .yearSelectContainer {
    text-align: center;
    font-size: 16px;
    position: relative;
    left: 0;
    top: 0;
    width: 470px;
    height: 30px;
    background-color: var(--box-background);
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 3px #888888;
    box-sizing: unset;

    .centeringDiv {
      display: flex;
      justify-content: center;
    }

    .textContainer {
      position: relative;
      float: left;
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  .weatherDataContainer {
    position: relative;
    left: 0;
    top: 15px;
    width: 470px;
    height: 700px;
    background-color: var(--box-background);
    padding: 10px 10px 20px 10px;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 3px #888888;
    box-sizing: unset;
    margin-bottom: 50px;
  }

  .griddle-table {
    border: solid #D4D4D4 1px;
    border-collapse: collapse;

    th,
    td {
      border: solid #D4D4D4 1px;
    }

    tr:nth-child(odd) {
      background-color: var(--odd-grid-row);
    }
  }

  .griddle-table-heading {
    color: #FFFFFF;
    font-weight: bold;
    height: 29px;
  }

  .griddle-table-heading-cell {
    background-color: var(--summary-label-background) !important;
  }

  .griddle-cell {
    padding: 3px 5px;
  }

  .weatherTypeHeader {
    width: 205px;
  }

  .valueHeader {
    width: 95px;
  }

  .miscHeader {
    width: 160px;
  }

  .alignRight {
    text-align: right;
  }

  .graphContainer {
    position: relative;
    float: left;
    width: 470px;
    height: 210px;
    margin-top: 10px;
  }

  .rc-calendar-fullscreen .rc-calendar-cell .rc-calendar-date,
  .rc-calendar-fullscreen .rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
    height: 75px;
  }

  .rc-calendar-full-header {
    background-color: var(--summary-label-background);
  }
}