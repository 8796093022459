    .dataContainer .temperatureSummaryDiv
    {
        position: relative;
        float: left;
        width: 271px;
        font-family: sans-serif;
        font-size: 16px;
        margin-right: 1px;
        height: 301px;
    }

    .dataContainer .weatherSummaryDiv
    {
        position: relative;
        float: left;
        width: 271px;
        width: 271px;
        font-family: sans-serif;
        font-size: 15px;
        margin-top: 1px;
        margin-right: 1px;
        height: 168px;
    }

    .dataContainer .weatherValueDiv
    {
        position: relative;
        float: right;
        text-align: right;
    }

    .dataContainer .spaceDivSmall
    {
        position: relative;
        float: left;
        width: 100%;
        height: 5px;
    }

    .dataContainer .spaceDivSmall
    {
        position: relative;
        float: left;
        width: 100%;
        height: 5px;
        font-size: 5px;
    }

    .dataContainer .highLowValuesSpacer
    {
        position: relative;
        float: left;
        width: 45px;
        height: 17px;
    }

    .dataContainer .highLowVColumn
    {
        position: relative;
        float: left;
        width: 49px;
        height: 34px;
        font-size: 12px;
        text-align: right;
        font-weight: bold;
        vertical-align: middle;
    }

    .dataContainer .highLowValuesColumn
    {
        position: relative;
        float: left;
        width: 97px;
        height: 17px;
        font-size: 12px;
        text-align: center;
        vertical-align: middle;
    }

    .dataContainer .graphTypeSelectionContainer
    {
        position: relative;
        float: left;
        width: 165px;
        margin-top: 20px;
    }

    .dataContainer .graphContainer
    {
        position: relative;
        float: left;
        width: 652px;
        margin-top: 20px;
    }

    .dataContainer .checkboxContainer
    {
        margin-bottom: 4px;
        font-size: 12px;
    }

    .dataContainer .boldText
    {
        font-weight: bold;
    }

    .dataContainer .redText
    {
        color: #ff0000;
    }

    .dataContainer .blueText
    {
        color: #0000ff;
    }

    .dataContainer .temperature
    {
        color: var(--temperature-color);
    }

    .dataContainer .thswIndex
    {
        color: #FF6633;
    }

    .dataContainer .heatIndex
    {
        color: #990099;
    }

    .dataContainer .windChill
    {
        color: #0000FF;
    }

    .dataContainer .humidity
    {
        color: var(--humidity-color);
    }

    .dataContainer .dewpoint
    {
        color: #009999;
    }

    .dataContainer .uvIndex
    {
        color: #D8B164;
    }

    .dataContainer .barometer
    {
        color: var(--barometer-color);
    }

    .dataContainer .solar
    {
        color: var(--solar-radiation-color);
    }

    .dataContainer .highWind
    {
        color: var(--high-wind-speed-color);
    }

    .dataContainer .avgWind
    {
        color: var(--average-wind-speed-color);
    }