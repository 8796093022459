

.radar
{
    margin: 0;
    background-color: #ffffff;
}

    .radar .mapContainer
    {
        position: absolute;
        top: 105px;
        bottom: 30px;
        left: 0;
        right: 0;
        float: left;
        display: block;
    }

        .radar .mapContainer .fullHeight
        {
            height: 100%;
        }

        .radar .mapContainer .map
        {
            height: 100%;
            border-radius: 5px;
        }

    .radar .buttonContainerDiv
    {
        position: absolute;
        right: 50px;
        bottom: 50px;
        background-color: #B6B7BC;
        border: solid 1px black;
        padding-top: 4px;
        width: 188px;
        text-align: center;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 3px #888888;
    }

    .radar .imageButtons
    {
        border: solid 0px black;
        padding: 0;
        margin-right: 3px;
        background-color: transparent;
    }

    .radar .timeContainer
    {
        width: 100%;
        height: 23px;
        text-align: right;
        background-color: #ffffff;
    }

    .radar .timeContainer .timeDiv
    {
        float: right;
        margin-right: 16px;
    }

    .radar .clear
    {
        clear: both;
    }