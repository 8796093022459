.graphTypeSelectionContainer
{
    position: relative;
    float: left;
    width: 165px;
    margin-top: 20px;
    background-color: var(--box-background);
    height: 285px;
}

.graphContainer
{
    position: relative;
    float: left;
    width: 652px;
    margin-top: 20px;
}

.checkboxContainer
{
    margin-bottom: 4px;
    font-size: 12px;
}