.annualClimateReport
{
    position: relative;
    float: left;
    width: 750px;
    margin-bottom: 50px;
}

    .annualClimateReport .annualReportDateDiv
    {
        position: relative;
        float: left;
        width: 100%;
        margin-left: 30px;
        text-align: center;
        margin-top: 5px;
    }

    .annualClimateReport .genericDiv
    {
        position: relative;
        float: left;
    }

    .annualClimateReport .spaceDivSmall
    {
        position: relative;
        float: left;
        width: 100%;
        height: 5px;
        font-size: 5px;
    }

    .annualClimateReport .noaaTextDiv
    {
        position: relative;
        float: left;
        font-family: Courier New;
        font-size: 10pt;
    }
    
    .annualClimateReport .annualReportDateDiv
    {
        position: relative;
        float: left;
        width: 750px;
        margin-left: 30px;
        text-align: center;
        margin-top: 5px;
    }
    
    .annualClimateReport .annualReportLongDiv
    {
        position: relative;
        float: left;
        width: 780px;
    }
    
    .annualClimateReport .annualReportPrecipDiv
    {
        position: relative;
        float: left;
        width: 375px;
    }
    
    .annualClimateReport .annualReportWindDiv
    {
        position: relative;
        float: left;
        width: 275px;
        padding-left: 75px;
    }

    .annualClimateReport pre
    {
        margin: 0;
    }