.cwop {
  margin: 0;

  .mapContainer {
    position: absolute;
    top: 155px;
    bottom: 30px;
    left: 0;
    right: 205px;
    float: left;
    display: block;

    .fullHeight {
      height: 100%;
    }

    .map {
      height: 100%;
    }
  }

  .stationContainer {
    position: relative;
    float: right;
    overflow-y: scroll;
    width: 205px;
    margin-top: 60px;
    height: calc(100vh - 190px);

    .staionDiv {
      padding: 2px 4px;
      cursor: pointer;
      color: var(--text-color);

      &:hover {
        text-decoration: underline;
      }

      &:nth-child(even) {
        background-color: var(--odd-grid-row);
      }
    }

    .checkboxContainer {
      position: relative;
      float: left;
      width: 33%;
    }

    .header {
      background-color: transparent;
    }
  }
}

.cwop .markerDiv {
  width: 250px;
  font-family: Sans-Serif;
  font-size: 10pt;
}

.cwop .titleDiv {
  position: fixed;
  font-family: Sans-Serif;
  vertical-align: middle;
  left: 20px;
  right: 20px;
  top: 90px;
}

.cwop .kmlText {
  font-family: Sans-Serif;
  font-size: 12pt;
}

.cwop .searchFieldDiv {
  position: relative;
  float: right;
  font-family: Sans-Serif;
  font-size: 12pt;
  font-weight: normal;
  text-align: right;
  width: 450px;
}

.cwop .locationTextBox {
  width: 250px;
}

.cwop .goButton {
  width: 75px;
  border-radius: 3px;
  margin-top: 5px;
  margin-left: 4px;
}

.cwop {
  .bookmarkDiv {
    float: right;
    margin-top: 4px;
    font-size: 12px;

    a {
      color: var(--text-link-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.cwop .clear {
  clear: both;
}

.gm-style {
  .gm-style-iw-c {
    background-color: var(--map-bubble-background);
  }

  .gm-style-iw-tc {
    &::after {
      background-color: var(--map-bubble-background);
    }
  }
}

.gm-style-iw-chr {
  button {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
}

.gm-ui-hover-effect > span {
  background: var(--text-color);
  height: 1rem !important;
  width: 1rem !important;
}

.marker-div {
  div {
    margin-bottom: .2rem;
  }

  a {
    color: var(--text-link-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, rgba(85, 85, 85, 1) 50%, rgba(85, 85, 85, 0) 51%, rgba(85, 85, 85, 0) 100%);
}