.monthlySummary
{
    position: absolute;
    top: 100px;
    left: 50%;
    margin-left: -430px;
    min-width: 860px;
    font-size: .85em;

    .p-calendar {
      width: 100%;
      height: 100%;

      .p-datepicker {
        background-color: transparent;
        color: var(--text-color);
        border: 0 none;

        .p-datepicker-header {
          background-color: transparent;
          color: var(--text-color);

          .p-datepicker-year {
            color: var(--text-color);
          }
        }

        .p-monthpicker {
          .p-monthpicker-month {
            &:hover {
              background-color: #57B6FF;
              color: #242424;
            }
          }
        }
      }
    }
}

    .monthlySummary .rc-calendar-full-header-switcher
    {
        display: none;
    }

    .monthlySummary .leftSideContainer
    {
        position: absolute;
        top: 0;
        height: 350px;
        left: 10px;
        width: 350px;
        background-color: var(--box-background);
        padding: 5px;
        border: solid 1px #000000;
        border-radius: 5px;
        box-shadow: 0 0 5px 3px #888888;
        box-sizing: unset;
    }

    .monthlySummary .calendarContainer
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 350px;
        padding: 0;
        margin: 0;
    }

    .monthlySummary .rc-calendar-full
    {
        width: 100%;
    }

    .monthlySummary .weatherDataContainer
    {
        position: absolute;
        left: 390px;
        top: 0;
        width: 470px;
        height: 700px;
        background-color: var(--box-background);
        padding: 10px 10px 20px 10px;
        border: 1px solid black;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 3px #888888;
        box-sizing: unset;
    }

    .monthlySummary .griddle-table-heading
    {
        color: #FFFFFF;
        font-weight: bold;
        height: 29px;
    }

    .monthlySummary .griddle-table-heading-cell
    {
        background-color: var(--summary-label-background) !important;
    }

    .monthlySummary .weatherTypeHeader
    {
        width: 205px;
    }

    .monthlySummary .valueHeader
    {
        width: 95px;
    }

    .monthlySummary .miscHeader
    {
        width: 160px;
    }

    .monthlySummary .alignRight
    {
        text-align: right;
    }

    .monthlySummary .griddle-table
    {
        border: solid #D4D4D4 1px;
        border-collapse: collapse;
    }

    .monthlySummary .griddle-table th, td
    {
        border: solid #D4D4D4 1px;
    }

    .monthlySummary .griddle-table tr:nth-child(odd)
    {
        background-color: var(--odd-grid-row);
    }

    .monthlySummary .griddle-cell
    {
        padding: 3px 5px;
    }

    .monthlySummary .graphContainer
    {
        position: relative;
        float: left;
        width: 470px;
        height: 210px;
        margin-top: 10px;
    }

    .monthlySummary .rc-calendar-fullscreen .rc-calendar-cell .rc-calendar-date, .rc-calendar-fullscreen .rc-calendar-month-panel-cell .rc-calendar-month-panel-month
    {
        height: 75px;
    }

    .monthlySummary .rc-calendar-full-header
    {
        background-color: var(--summary-label-background);
    }

    .monthlySummary .rc-calendar {
        background-color: var(--box-background);
        color: var(--text-color);
    }

    .monthlySummary .rc-select-selection {
        background-color: #555555;
        color: var(--text-color);
    }

    .monthlySummary .rc-calendar-month-panel-cell .rc-calendar-month-panel-month {
        color: var(--text-color);
    }

    .monthlySummary .rc-calendar-month-panel-cell .rc-calendar-month-panel-month:hover {
        background-color: #777777;
    }

    .monthlySummary .rc-calendar-fullscreen .rc-calendar-month-panel-selected-cell .rc-calendar-month-panel-month {
        background-color: #777777;
    }