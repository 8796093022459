.dataContainer
{
    position: relative;
    top: 10px;
    left: 0px;
    width: 100%;
    min-width: 840px;
    height: 1px;
    overflow: visible;
}

    .dataContainer .centeringDiv
    {
        position: relative;
        margin-left: -417px;
        left: 50%;
        width: 836px;
        height: 700px;
        margin-bottom: 50px;
        background-color: var(--box-background);
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 3px;
        border: 1px solid #585A5A;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 3px #888888;
    }

    .dataContainer .mainSummaryBox
    {
        width: 817px;
        position: relative;
        float: left;
        border: solid 2px var(--weather-value-border);
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 2px;
        padding-right: 1px;
        border-radius: 5px;
        height: 375px;
        box-sizing: unset;
    }

    .dataContainer .lastUpdateDiv
    {
        position: relative;
        float: left;
        width: 100%;
        border-bottom: solid 1px var(--weather-value-border);
        font-family: Garamond sans-serif;
        font-size: 14px;
        text-align: center;
        padding-bottom: 2px;
    }

    .dataContainer .temperatureSummaryDiv
    {
        position: relative;
        float: left;
        width: 271px;
        font-family: sans-serif;
        font-size: 16px;
        margin-right: 1px;
        height: 301px;
    }

    .dataContainer .weatherSummaryDiv
    {
        position: relative;
        float: left;
        width: 271px;
        width: 271px;
        font-family: sans-serif;
        font-size: 15px;
        margin-top: 1px;
        margin-right: 1px;
        height: 168px;
        box-sizing: unset;
    }

    .dataContainer .summaryLabelDiv
    {
        position: relative;
        float: left;
        width: 100%;
        color: var(--text-color);
        background-color: var(--summary-label-background);
        text-align: center;
        padding: 2px 0;
        margin-bottom: 3px;
        border-radius: 3px;
    }

    .dataContainer .currentTemperatureDiv
    {
        position: relative;
        float: left;
        width: 248px;
        font-size: 20px;
        font-weight: bold;
        height: 23px;
        text-align: center;
        border-bottom: solid 1px var(--weather-value-border);
        margin: 8px 0 0 2px;
        padding: 3px 10px;
        box-sizing: unset;
    }

    .dataContainer .weatherDataDiv
    {
        position: relative;
        float: left;
        width: 248px;
        height: 18px;
        text-align: left;
        font-size: 14px;
        border-bottom: solid 1px var(--weather-value-border);
        padding: 3px 10px;
        margin: 0 0 0 3px;
        box-sizing: unset;
    }

    .dataContainer .weatherDataTopDiv
    {
        position: relative;
        float: left;
        width: 248px;
        height: 18px;
        text-align: left;
        font-size: 14px;
        border-bottom: solid 1px var(--weather-value-border);
        padding: 3px 10px 2px 10px;
        margin: 14px 0 0 2px;
        vertical-align: bottom;
        box-sizing: unset;
    }

    .dataContainer .weatherValueDiv
    {
        position: relative;
        float: right;
        text-align: right;
    }

    .dataContainer .spaceDivSmall
    {
        position: relative;
        float: left;
        width: 100%;
        height: 5px;
    }

    .dataContainer .spaceDivSmall
    {
        position: relative;
        float: left;
        width: 100%;
        height: 5px;
        font-size: 5px;
    }

    .dataContainer .highLowValuesSpacer
    {
        position: relative;
        float: left;
        width: 45px;
        height: 17px;
    }

    .dataContainer .highLowVColumn
    {
        position: relative;
        float: left;
        width: 49px;
        height: 34px;
        font-size: 12px;
        text-align: right;
        font-weight: bold;
        vertical-align: middle;
    }

    .dataContainer .highLowValuesColumn
    {
        position: relative;
        float: left;
        width: 97px;
        height: 17px;
        font-size: 12px;
        text-align: center;
        vertical-align: middle;
    }

    .dataContainer .boldText
    {
        font-weight: bold;
    }

    .dataContainer .redText
    {
        color: var(--red-text);
    }

    .dataContainer .blueText
    {
        color: var(--blue-text);
    }

    .dataContainer .temperature
    {
        color: var(--temperature-color);
    }

    .dataContainer .thswIndex
    {
        color: var(--thsw-index-color);
    }

    .dataContainer .heatIndex
    {
        color: var(--heat-index-color);
    }

    .dataContainer .windChill
    {
        color: var(--wind-chill-color);
    }

    .dataContainer .humidity
    {
        color: var(--humidity-color);
    }

    .dataContainer .dewpoint
    {
        color: var(--dew-point-color);
    }

    .dataContainer .uvIndex
    {
        color: var(--uv-index-color);
    }

    .dataContainer .barometer
    {
        color: var(--barometer-color);
    }

    .dataContainer .solarRadiation
    {
        color: var(--solar-radiation-color);
    }

    .dataContainer .highWindSpeed
    {
        color: var(--high-wind-speed-color);
    }

    .dataContainer .averageWindSpeed
    {
        color: var(--average-wind-speed-color);
    }

    .dataContainer .rainfall
    {
        color: var(--rainfall-color);
    }